














































import {
  defineComponent,
  useContext,
  ref,
  onMounted,
} from '@nuxtjs/composition-api';
import { useCart } from '@wemade-vsf/customer';
import { SfHeading, SfButton } from '@storefront-ui/vue';
import RadioBox from 'components/theme/Form/RadioBox.vue';
import type { AvailablePaymentMethod } from '@wemade-vsf/magento-api';

export default defineComponent({
  name: 'PaymentMethodSelector',
  components: {
    RadioBox,
    SfHeading,
    SfButton
  },
  setup (props, { emit }) {
    const { app } = useContext();
    const { svea } = app.$wm.checkout;
    const { cart } = useCart();

    const sveaPm = svea.paymentMethod;

    const selectedOption = ref<string>('');
    const availablePaymentMethods = ref<AvailablePaymentMethod[]>()
    const loading = ref(true);

    function emitSelect() {
      emit('select:method', selectedOption.value);
    }

    async function fetchPaymentMethods() {
      const { data } = await app.$vsf.$magento.api.getAvailablePaymentMethods({ cartId: cart.value.id })
      availablePaymentMethods.value = data?.cart?.available_payment_methods ?? []
    }

    function isAvailable(code: string) {
      if (code === 'other') {
        const otherAvailable = availablePaymentMethods.value?.filter(pm => pm.code !== sveaPm) ?? [];
        return otherAvailable?.length > 0;
      }
      return availablePaymentMethods.value?.some(pm => pm.code === code)
    }

    onMounted(async () => {
      loading.value = true;
      await fetchPaymentMethods();
      loading.value = false;
    })

    return {
      sveaPm,
      selectedOption,
      emitSelect,
      isAvailable,
      availablePaymentMethods
    }
  }
})
